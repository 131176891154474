import React from "react"

import Layout from "../components/layout"
import Nav from "../components/Nav"
import Footer from "../components/Footer"

import PageHeader from "../components/Header"

import Contact from "../components/Contact"

const ContactPage = () => {

  return (
    <Layout
      title="Rolteca | Contacta"
      description="Contacta con nosotros, estaremos encantados de colaborar contigo"
      slug={"/contacta"}>
      <Nav />
      <PageHeader title="Contacta" />
      <Contact/>
      <Footer />
    </Layout>
  )
}

export default ContactPage
