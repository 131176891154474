import React from "react";

const Contact = () => {
  return (
    <div>
      <section className="contact-info-one">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="contact-info-one__single">
                <div className="contact-info-one__icon">
                  <i className="kipso-icon-manager"></i>
                </div>
                <h2 className="contact-info-one__title">¿Quien somos?</h2>
                <p className="contact-info-one__text text-justify inner-padding-text">
                  Somos un grupo de personas <strong>jugadores</strong> de rol e informáticos principalmente que nos hemos propuesto hacer proyectos para mejorar la experiencia de la comunidad en juegos de rol y de mesa
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="contact-info-one__single">
                <div className="contact-info-one__icon">
                  <i className="kipso-icon-placeholder"></i>
                </div>
                <h2 className="contact-info-one__title">¿Dónde encontrarnos?</h2>
                <p className="contact-info-one__text text-justify inner-padding-text">
                  No nos engañemos, esto no es una empresa y no tenemos sede, pero puedes contactar con nosotros en nuestras redes sociales o <br/> en <a href="mailto:contacto@rolteca.com">nuestro correo</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contact-one">
        <div className="container">
          <h2 className="contact-one__title text-center">
            Conctacta con nosotros <br />
          </h2>
          <p>Si vas a lanzar un Crowfounding y crees que podemos darte visibilidad, si tienes una idea para una app o una web que sientes que ayudará a la comunidad de roleros ya sea online o en mesa, quieres darnos tu opinión sobre cómo mejorar alguna de nuestras aplicación o quieres pertenecer al equipo de redactores de rolteca.com, no dudes en contactar con nosotros mediante este formulario o en  <a href="mailto:contacto@rolteca.com">contacto@rolteca.com</a> </p>
          <form
            action="#"
            className="contact-one__form contact-form-validated"
            noValidate="novalidate"
          >
            <div className="row low-gutters">
              <div className="col-lg-6">
                <input type="text" name="name" placeholder="Nombre" />
              </div>
              <div className="col-lg-6">
                <input type="text" placeholder="Email" name="email" />
              </div>
              <div className="col-lg-12">
                <textarea placeholder="Tu mensaje" name="message"></textarea>
                <div className="text-center">
                  <button type="submit" className="contact-one__btn thm-btn">
                    Enviar
                  </button>
                </div>
              </div>
            </div>
          </form>
          <div className="result text-center"></div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
