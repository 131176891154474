import React from "react";
import { Helmet } from "react-helmet";
import appleTouch from "../assets/images/favicons/favicon.svg";
import fav32 from "../assets/images/favicons/favicon.svg";
import fav16 from "../assets/images/favicons/favicon.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/plugins/fontawesome-free-5.11.2-web/css/all.min.css";
import "../assets/plugins/kipso-icons/style.css";
import "../assets/css/animate.min.css";
import "../assets/css/style.css";
import "../assets/css/responsive.css";

const Layout = (props) => {
  let processImage = "";
  if(props.image !== undefined && props.image !== null){
    if(props.image.sharp !== undefined){
      processImage = props.image.sharp.fluid.src;
    }else{
      processImage = props.image;
    }
  }
  let description = props.description
  if(description?.length > 130) description = description.substr(0,129)
  return (
    <div>
      <Helmet>
        <title>{props.title}</title>

        <meta charSet="UTF-8" />
        <meta name="description" content={"▷" + description}/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        <link rel="icon" type="image/png" href={fav16} />

        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta property="og:title" content={props.title} />
        <meta property="og:description" content={description} />
        { props.image ?
          (<meta property="og:image" content={ "https://rolteca.com" + processImage } />)
          :
          (<meta property="og:image" content={ fav32 } />)
        }
        <meta property="og:url" content={"https://rolteca.com/" + props.slug} />
        <meta name="twitter:card" content="summary" />
        <meta property="og:type" content="article" />
        <meta property="og:locale" content="es_ES" />
        <link rel="canonical" href={"https://rolteca.com" + props.slug}/>

        <link
          href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,500i,600,700,800%7CSatisfy&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <div className="page-wrapper">{props.children}</div>
    </div>
  );
};

export default Layout;
